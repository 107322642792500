import { createContext, useContext, useState } from "react";

const StoreContext = createContext();
const defState = {
  style: {
    isSideMenuOpen: false,
    isMobile: false,
    height: 0,
    width: 0,
    isScroll100: false,
  }
};

const reducers = {
};

export function StoreWrapper({ children }) {
  const [state, setState] = useState(defState);

  const dispatch = (content, payload, action) => {
    if (!content) {
      return;
    }

    payload = reducers[content]
      ? reducers[content](state[content], payload, action, {
          state: { ...state },
        }) || payload
      : payload;

    if ("delete" in payload) {
      const d = {};
      d[content] = payload.delete;
      setState(d);
    } else {
      const localState = state[content] || {};
      const newState = { ...localState, ...payload };
      const d = { ...state };
      d[content] = newState;
      setState(d);

    }
  };

  const value = { ...state, ...state.style, dispatch };

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
}

export function useStore() {
  return useContext(StoreContext);
}
